import React, { useState, useEffect } from 'react';
import Slider from "react-slick";

import { BASE_URL } from '../../utils/constants.js';

import './style.scss';

const SermonLayout = () => {
	const [playlists, setPlayLists] = useState([])
	const [latestVideos, setLatestVideos] = useState([])

	useEffect(() => {
		getSermonData()
	}, [])

	const getSermonData = () => {
		fetch(`${BASE_URL}/api/open/sermon_data`)
			.then(response => response.json())
			.then(data => {
				console.log("resp", data);
				setPlayLists(data.Data.playLists);
				setLatestVideos(data.Data.latestVideos);
			})
	}

	const settings = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 3,
		slidesToScroll: 1,
		responsive: [
			{
				breakpoint: 1450,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 1,
					infinite: true
				}
			},
			{
				breakpoint: 850,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1
				}
			},
			{
				breakpoint: 500,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			}
		]
	};

	return (
		<div className='sermon-layout'>
			{
				latestVideos.length > 0 ?
					<div className="sermon-list mb-5">
						<h4 className="title mb-3">Latest Videos</h4>
						<div className="slider">
							<Slider {...settings}>
								{
									latestVideos.map(video => {
										return (
											<a href={`https://www.youtube.com/watch?v=${video.videoID}`} target="_blank" className='sermon-item'>
												<div className="image">
													<img src={video.thumbnail} alt={video.title} />
												</div>
												<div className="body">
													<div className="title">{video.title}</div>
													<span className="ms-auto note">Click to view on youtube</span>
												</div>
											</a>
										)
									})
								}
							</Slider>
						</div>
					</div>
					:
					""
			}
			{
				playlists.length > 0 ?
					playlists.map(playlist => {
						return (
							<div className="sermon-list mb-5">
								<h4 className="title mb-3">{playlist.title}</h4>
								<div className="slider">
									<Slider {...settings}>
										{
											playlist.videos.map(video => {
												return (
													<a href={`https://www.youtube.com/watch?v=${video.videoID}`} target="_blank" className='sermon-item'>
														<div className="image">
															<img src={video.thumbnail} alt={video.title} />
														</div>
														<div className="body">
															<div className="title">{video.title}</div>
															<span className="ms-auto note">Click to view on youtube</span>
														</div>
													</a>
												)
											})
										}
										{
											playlist.TotalVideoCount > 10 ?
												<a href={`https://www.youtube.com/playlist?list=${playlist.id}`} target="_blank" className='sermon-item'>
													<div className="bold-text">
														{playlist.TotalVideoCount - 10}+ Videos
													</div>
													<div className="body">
														<span className="ms-auto mt-auto note">Click to view on youtube</span>
													</div>
												</a>
												:
												""
										}
									</Slider>
								</div>
							</div>
						)
					})
					:
					""
			}
		</div>
	)
}

export default SermonLayout