import React from 'react';

import MainLayout from '../layouts/mainlayout/MainLayout';
import PageHeader from '../components/pageheader/PageHeader';
import SermonLayout from '../components/sermons/SermonLayout';

function Sermons() {
	return (
		<MainLayout title="Sermons">
			<PageHeader title="Sermons" />
			<SermonLayout />
		</MainLayout>
	);
}
export default Sermons;